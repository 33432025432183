<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { NLayoutContent, useMessage } from 'naive-ui'
import { useRoute, useRouter } from 'vue-router'
import SiderBar from './siderBar/index.vue'
import FooterBar from './footerBar/index.vue'
import Login from './components/Login.vue'
import PayDialog from './components/PayDialog.vue'
import GoodsDialog from './components/GoodsDialog.vue'
import BalanceNot from "./components/BalanceNot.vue"
import NoticeDialog from './components/NoticeDialog.vue'
import BindWxDialog from './components/BindWx.vue'
import SignInDialog from './components/SignInDialog.vue'
import PaySuccesslog from './components/PaySuccesslog.vue'
import ModelDialog from './components/modelDialog.vue'
import PackageDialog from './components/packageDialog.vue'
import GoogleDialog from './components/Login/GoogleDialog.vue'
import ResetPasswordDialog from './components/ResetPasswordDialog.vue'
import SignInGoogleDialog from './components/SignInGoogleDialog.vue'
import Header from './components/Header.vue'
import UserBulletin from './components/UserBulletin.vue'
import { useBasicLayout } from '@/hooks/useBasicLayout'
import { useAppStore, useAuthStore, useGlobalStoreWithOut } from '@/store'
import { fetchLoginByCodeAPI, fetchWxLoginRedirectAPI, fetchWxLoginSaveInfoAPI } from '@/api/user'
import Loading from '@/components/base/Loading.vue'
import type { ResData } from '@/api/types'

const authStore = useAuthStore()
const route = useRoute()
const router = useRouter()
const useGlobalStore = useGlobalStoreWithOut()
const appStore = useAppStore()
const ms = useMessage()
const payDialog = computed(() => useGlobalStore.payDialog)
const goodsDialog = computed(() => useGlobalStore.goodsDialog)
const noticeDialog = computed(() => useGlobalStore.noticeDialog)
const bindWxDialog = computed(() => useGlobalStore.bindWxDialog)
const signInDialog = computed(() => useGlobalStore.signInDialog)
const modelDialog = computed(() => useGlobalStore.modelDialog)
const balanceNotDialog = computed(() => useGlobalStore.balanceNotDialog)
const paySuccesslog = computed(() => useGlobalStore.paySuccesslog)
const warnNotDialog = computed(() => useGlobalStore.warnNotDialog)
const packageDialog = computed(() => useGlobalStore.packageDialog)
const googleDialog = computed(() => useGlobalStore.googleDialog)
const resetPassWordDialog = computed(() => useGlobalStore.resetPassWordDialog)
const signInGoogleDialog = computed(() => useGlobalStore.signInGoogleDialog)
const { isMobile } = useBasicLayout()
const loginDialog = computed(() => authStore.loginDialog)
const globalConfigLoading = computed(() => authStore.globalConfigLoading)
const theme = computed(() => appStore.theme)
const bgColor = computed(() => theme.value === 'dark' ? '#24272e' : '#fff')
const isLogin = computed(() => authStore.isLogin)
const wechatSilentLoginStatus = computed(() => Number(authStore.globalConfig?.wechatSilentLoginStatus) === 1)
const homePath = computed(() => authStore.globalConfig?.clientHomePath)
const userName = computed(() => authStore.userInfo?.username)
// const globalConfig = computed(() => authStore.globalConfig?.wranNotice)

const hasShownAnnouncement = localStorage.getItem('hasShownAnnouncement');
if(userName.value == 'xxx' && !hasShownAnnouncement){
  useGlobalStore.updateWarnNotDialog(true)
}

/* 登录成功后让用户勾选用户协议 */
const userAgreement = computed(() => authStore.globalConfig.userAgreement)
const userPrivacy = computed(() => authStore.globalConfig.userPrivacy)

const registerForm = ref({
  consentTimestamp:'',
  userAgreementVersion:'',
  privacyPolicyVersion:''
})

function initFormValue(){
  let Math1 = userAgreement.value.split('/').pop();
  let Math2 = userPrivacy.value.split('/').pop();
  let userAgreementMatch = Math1.split('.').shift();
  let userPrivacyMatch = Math2.split('.').shift();
  registerForm.value.privacyPolicyVersion = userPrivacyMatch
  registerForm.value.userAgreementVersion = userAgreementMatch
  registerForm.value.consentTimestamp = new Date().toISOString()
}

async function verifyInfo(){
  const params: any = registerForm.value
  await fetchWxLoginSaveInfoAPI(params)
}

function verifyProtocol() {
  const consentState = computed(() => authStore.userInfo.consentState)
  if (!consentState.value) {
    initFormValue()
    verifyInfo()
  }
}


/* 如果在vx环境并且携带了code则静默登录 */
function handleCheckOtherLoginByToken() {
  const { token } = route.query
  if (token) {
    authStore.setToken(token)
    const name = route.name
    router.replace({ name, query: {} })
    ms.success('账户登录成功、开始体验吧！')
    authStore.getUserInfo()
    useGlobalStore.updateLoginCount()
    verifyProtocol()
  }
}

/* 微信环境静默登录 */
async function loginByWechat() {
  if (homePath.value || !wechatSilentLoginStatus.value)
    return
  if (isLogin.value)
    return

  /* 如果在vx环境并且携带了code则静默登录 */
  const { code } = route.query
  if (code) {
    const res: ResData = await fetchLoginByCodeAPI({ code: code as string })
    if (res.success) {
      authStore.setToken(res.data)
      authStore.getUserInfo()
      initFormValue()
      verifyInfo()
    }
  }
  else {
    const url = window.location.href.replace(/#.*$/, '')
    const res: ResData = await fetchWxLoginRedirectAPI({ url })
    if (res.success)
      window.location.href = res.data
  }
}

function init() {
  const ua = window.navigator.userAgent.toLowerCase()
  if (ua.match(/MicroMessenger/i) && ua?.match(/MicroMessenger/i)?.[0] === 'micromessenger')
    loginByWechat()
}

onMounted(() => {
  init()
  handleCheckOtherLoginByToken()
})

const getMobileMainClass = computed(() => {
  if (isMobile.value)
    return ['rounded-none', 'shadow-none']
  return ['dark:bg-[#131314]', 'pl-[0px]', 'mr-[0px]']
})

const getMobileLayoutClass = computed(() => {
  if (isMobile.value)
    return ['flex-col', 'pb-0', 'pt-[45px]', 'border-t', 'pb-[60px]']
  return ['pb-[0px]', 'pt-[47px]']
})

</script>

<template>
  <div class="h-full transition-all p-0 ">
    <div class="h-full overflow-hidden">
      <Header />
      <div class="z-40 transition flex h-full relative dark:bg-[#131314] bg-[#f7f8fc]" :class="getMobileLayoutClass">
        <SiderBar v-if="!isMobile" />
        <NLayoutContent class="h-full" style="flex: 1" :class="getMobileMainClass">
          <RouterView v-slot="{ Component, route }">
            <component :is="Component" :key="route.fullPath" />
          </RouterView>
        </NLayoutContent>
        <FooterBar v-if="isMobile" />
        <Loading v-if="globalConfigLoading" :bg-color="bgColor" />
      </div>
      <!-- <Login :visible="loginDialog" /> -->
      <PayDialog :visible="payDialog" />
      <GoodsDialog :visible="goodsDialog" />
      <NoticeDialog :visible="noticeDialog" />
      <BindWxDialog :visible="bindWxDialog" />
      <SignInDialog :visible="signInDialog" />
      <ModelDialog :visible="modelDialog" />
      <BalanceNot :visible="balanceNotDialog" />
      <PaySuccesslog :visible="paySuccesslog" />
      <UserBulletin :visible="warnNotDialog"  />
      <PackageDialog :visible="packageDialog" />
      <GoogleDialog :visible="loginDialog" />
      <ResetPasswordDialog :visible="resetPassWordDialog" />
      <SignInGoogleDialog :visible="signInGoogleDialog" />
    </div>
  </div>
</template>
<style lang="less" scoped>

</style>
