<script setup lang='ts'>
import type { FormRules } from 'naive-ui'
import { NButton, NCollapse, NCollapseItem, NForm, NFormItem, NIcon, NInput, NModal, NSelect, NSlider, useMessage } from 'naive-ui'
import { computed, ref, watch } from 'vue'
import { CloseOutline, SettingsOutline } from '@vicons/ionicons5'
import { useChatStore, useGlobalStoreWithOut } from '@/store'
import { fetchUpdateGroupAPI } from '@/api/group'
import { SvgIcon } from '@/components/common'

defineProps<Props>()

const formRef = ref(null)
const useGlobalStore = useGlobalStoreWithOut()
const chatStore = useChatStore()
const loading = ref(false)
const modelForm = ref({
  systemMessage: '',
  modelTypeId: null,
  topN: 0.8,
  maxResponseTokens: 0,
  rounds: 0,
  title: 'New Chat',
  isSticky: false,
  pluginId: null,
})
const activeChatGroupPluginId = computed(() => chatStore?.activeChatGroup?.pluginId)

const rules: FormRules = {
  title: [
    { required: true, message: 'Please enter a chat group name!', trigger: 'blur' },
    { min: 2, max: 20, message: 'Length between 2 and 10 characters', trigger: 'blur' },
  ],
  modelTypeId: [
    { required: true, message: 'Please select a dialogue group model!' },
  ],
}
/* 所有可用模型分类 */
const modelList = computed(() => chatStore.modelList)

/* 当前对话组信息 */
const activeChatGroup = computed(() => {
  return chatStore.activeChatGroup
})

const activeModelTypeInfo: any = computed(() => {
  return modelList.value.find((item: any) => item.id === modelForm.value.modelTypeId)
})

const activeGroupAppId = computed(() => chatStore.activeGroupAppId)
const activeAppFormat = computed(() => chatStore.activeAppFormat)

/* 不是openai的模型暂时不让设置预设 */
const disabledSystemMessage = computed(() => {
  return Number(activeGroupAppId.value) > 0 || Boolean(activeChatGroupPluginId.value)
})

/* 温度 */
const maxTemperature = ref(1.6)

/* 当前的对话组id */
const chatGroupId = computed(() => chatStore.active)
const activeAppInfo = computed(() => chatStore.activeAppInfo)

watch(activeChatGroup, (val) => {
  if (!val)
    return
  compilerConfig(val)
})

interface Props {
  visible: boolean
}

const message = useMessage()
const showResetBtn = ref(false)

async function compilerConfig(val: any) {
  let config = val?.config ? JSON.parse(val.config) : chatStore.baseConfig
  if (!config?.modelTypeId || !config || !config.modelConfig)
    config = await chatStore.getBaseModelConfig()
  const { title, isSticky, pluginId } = val
  const { modelTypeId, modelConfig } = config
  const { maxResponseTokens, rounds, systemMessage, topN } = modelConfig
  modelForm.value = {
    pluginId,
    isSticky,
    title,
    systemMessage,
    modelTypeId,
    topN,
    maxResponseTokens,
    rounds,
  }
}

async function handleReset() {
  const config = chatStore.baseConfig
  compilerConfig(config)
}

function handleUpdate(val: any) {
  showResetBtn.value = val.includes('1')
}

/* 修改对话组模型配置 */
async function handleUpdateConfig() {
  formRef.value?.validate(async (err: any) => {
    if (err)
      return
    const config = {
      modelTypeId: modelForm.value.modelTypeId,
      modelConfig: modelForm.value,
    }
    const { pluginId, isSticky, title } = modelForm.value
    try {
      loading.value = true
      await fetchUpdateGroupAPI({
        groupId: chatGroupId.value,
        config: JSON.stringify(config),
        pluginId: -1,
        isSticky,
        title,
      })
      loading.value = false
      message.success('The current dialogue group configuration has been modified successfully!')
      await chatStore.queryMyGroup()
      useGlobalStore.updateModelDialog(false)
    }
    catch (error) {
      loading.value = false
    }
  })
}

function openDialog() {}

function handleCloseDialog() {
  showResetBtn.value = false
}
</script>

<template>
  <NModal
    class="w-11/12 md:w-7/12 lg:w-5/12"
    :show="visible" :on-after-enter="openDialog"
    :on-after-leave="handleCloseDialog"
  >
    <div class="py-3 px-5 bg-white dark:bg-[#0b0e14] rounded ">
      <div class="absolute top-3 right-3 cursor-pointer" @click="useGlobalStore.updateModelDialog(false)">
        <NIcon size="20" color="#0e7a0d">
          <CloseOutline />
        </NIcon>
      </div>
      <div class="flex font-bold mb-[20px] bg-currentflex items-center ">
        <NIcon size="24" color="#0e7a0d">
          <SettingsOutline />
        </NIcon>
        <span class="ml-[8px] mt-1 text-lg">Dialog Group Configuration</span>
      </div>

      <div class=" pb-4">
        <NForm ref="formRef" :model="modelForm" :rules="rules">
          <NFormItem path="title" label="Name">
            <NInput v-model:value="modelForm.title" :disabled="Boolean(chatStore?.activeGroupAppId)" @keydown.enter.prevent />
          </NFormItem>
          <NFormItem path="modelTypeId" label="Select Model">
            <NSelect v-model:value="modelForm.modelTypeId" :disabled="Boolean(activeChatGroupPluginId) || Boolean(activeAppFormat?.modelTypeId)" label-field="modelName" value-field="id" placeholder="请选用当前聊天组所需的模型！" :options="modelList" />
          </NFormItem>
        </NForm>
        <div class="pb-1">
          Custom character presets
        </div>
        <NInput v-model:value="modelForm.systemMessage" type="textarea" :disabled="disabledSystemMessage " placeholder="Custom head presets, give your AI an identity..." />
      </div>

      <div class="mt-5 bg-[#fafbfc] px-2 py-2 dark:bg-[#2d3137] rounded-md">
        <NCollapse default-expanded-names="" accordion :on-update:expanded-names="handleUpdate">
          <NCollapseItem name="1">
            <template #header>
              <div>
                Advanced Configuration
                <span class="text-xs text-neutral-500">(More detailed configuration content)</span>
              </div>
            </template>
            <template #header-extra>
              <div @click.stop="handleReset">
                <NButton v-if="showResetBtn" text type="error">
                  Reset
                </NButton>
              </div>
            </template>
            <div class="mt-2">
              <div>
                <div class=" w-full flex justify-between">
                  <span class="w-[150px]">Randomness of topic</span>
                  <div class="flex w-[200px] items-center">
                    <NSlider v-model:value="modelForm.topN" :step="0.1" :max="maxTemperature" />
                    <!-- <span class="w-[70px] text-right">
                      {{ maxTemperature }}
                    </span> -->
                  </div>
                </div>
                <div class="mt-2 text-xs text-slate-500 dark:text-slate-400">
                  Higher values ​​will make the output of the same question more random.
                </div>
              </div>
              <div v-if="modelForm?.maxResponseTokens" class="mt-6">
                <div class=" w-full flex justify-between">
                  <span class="w-[150px]">Reply Token Limit</span>
                  <div class="flex w-[200px] items-center">
                    <NSlider v-model:value="modelForm.maxResponseTokens" :step="100" :max="activeModelTypeInfo?.maxResponseTokens" />
                  </div>
                </div>
                <div class="mt-2 text-xs text-slate-500 dark:text-slate-400">
                  Single reply token limit. If it is too high, it will compress the context memory space.
                </div>
              </div>
              <div class="mt-6">
                <div class=" w-full flex justify-between">
                  <span class="w-[150px]">Limitation of the number of contexts</span>
                  <div class="flex w-[200px] items-center">
                    <NSlider v-model:value="modelForm.rounds" :step="1" :max="activeModelTypeInfo?.maxRounds" />
                  </div>
                </div>
                <div class="mt-2 text-xs text-slate-500 dark:text-slate-400">
                  The number of conversations recorded for a single reply, how many conversations do you want the AI ​​to remember each time?
                </div>
              </div>
            </div>
          </NCollapseItem>
        </NCollapse>
      </div>
      <div class="mt-4 flex items-center justify-end space-x-4">
        <NButton size="small" @click="useGlobalStore.updateModelDialog(false)">
          <SvgIcon icon="line-md:cancel" class="text-lg mr-1" />
          Cancel
        </NButton>
        <NButton size="small" type="primary" :loading="loading" @click="handleUpdateConfig">
          <SvgIcon icon="ic:outline-save" class="text-lg mr-1" />
          Save
        </NButton>
      </div>
    </div>
  </NModal>
</template>
