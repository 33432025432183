import { createApp , computed } from 'vue'
import '@/styles/lib/viewer.css'
import VueViewer from 'v-viewer'
import { MotionPlugin } from '@vueuse/motion'
import App from './App.vue'
import { setupI18n } from './locales'
import { setupAssets, setupScrollbarStyle } from './plugins'
import { setupStore } from './store'
import { setupRouter } from './router'
import { vLoading } from '@/loading/index'
import '@/styles/index.less'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import vue3GoogleLogin from 'vue3-google-login'
import { useAuthStore } from '@/store'
const authStore = useAuthStore()

// import fullscreenLoading from './components/common/FullScreenLoading'
// import '@/components/common/FullScreenLoading/style.css'

if (import.meta.env.MODE !== 'development')
  (window as any)?.ConsoleBan?.init({ redirect: 'about:blank' })

async function bootstrap() {
  const app = createApp(App)
  app.use(VueViewer)
  app.use(MotionPlugin)
  setupAssets()
  setupScrollbarStyle()
  setupStore(app)
  setupI18n(app)
  await setupRouter(app)
  app.mount('#app')
  app.directive('loading', vLoading)
  app.use(ElementPlus)
  // 这里需要在 store 注册之后再获取，要不然获取不到数据
  const googleClientId = computed(() => authStore.globalConfig?.googleClientId)
  app.use(vue3GoogleLogin,{
    clientId: googleClientId.value
  })
}

bootstrap()
