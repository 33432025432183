<script setup lang='ts'>

import { NIcon, NModal, useMessage } from 'naive-ui'
import { computed, ref } from 'vue'
import { CloseOutline, PaperPlaneOutline } from '@vicons/ionicons5'
import { useAuthStore, useGlobalStore } from '@/store'
import { useBasicLayout } from '@/hooks/useBasicLayout'
import { fetchPayPalCreateOrderAPI, fetchPayPalApproveOrderAPI } from '@/api/order'

defineProps<Props>()
const { isMobile } = useBasicLayout()
const authStore = useAuthStore()
const useGlobal = useGlobalStore()
const ms = useMessage()

interface Props {
  visible: boolean
}

const orderInfo = computed(() => useGlobal?.orderInfo)

function handleCloseDialog() {
  // useGlobal.updateOrderInfo({})
}

async function handleOpenDialog() {
  // try {
  //   await loadPayPalSdk("AesyyoqjGt6ZYvN4j0Iy63wUH9CFN-mYaAhqgyppMYQVZ-W-s1b_HQur69LGleszzMF_Uw3szCMAd6tF"); // 替换为你的 Client ID
  //   renderPayPalButton();
  // } catch (error) {
  //   console.error("加载 PayPal 按钮失败：", error);
  // }
}

async function createPayment(){
 const { data } = await fetchPayPalCreateOrderAPI({ total: '30.00', currency: 'USD' })
//  window.location.href = data.approvalUrl;
  window.open(data.approvalUrl, '_blank');
}

// // 动态加载PayPal的SDK
// async function loadPayPalSdk(clientId: string): Promise<void> {
//   return new Promise((resolve, reject) => {
//     const existingScript = document.querySelector(
//       `script[src*="https://www.paypal.com/sdk/js"]`
//     );
//     if (existingScript) {
//       resolve(); // SDK 已加载
//       return;
//     }
//     const script = document.createElement("script");
//     script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&locale=zh_CN`;
//     script.async = true;
//     script.onload = () => resolve();
//     script.onerror = () => reject(new Error("加载 PayPal SDK 失败"));
//     document.head.appendChild(script);
//   });
// }

// // 渲染PayPal支付按钮
// function renderPayPalButton() {
//   (window as any).paypal.Buttons({
//     // 创建订单
//     async createOrder() {
//       let results: any = await fetchOrderBuyAPI({ goodsId: orderInfo.value.pkgInfo.id })
//       return results.data
//     },
//     // 订单批准后捕获订单
//     async onApprove(data: any){
//       console.log(data)
//       // let { id, orderId } = data
//       // let results = await fetchPayPalApproveOrderAPI({ id, orderId })
//       // console.log(results)
//       // return results
//     },
//     onError(err:any){
//       console.error("支付失败：", err);
//     }
//   }).render("#paypal-button-container");
// }
</script>

<template>
  <NModal :show="visible" transform-origin="center" style="width: 90%; max-width: 750px" :on-after-enter="handleOpenDialog" :on-after-leave="handleCloseDialog">
    <div class="p-4 bg-white rounded dark:bg-slate-800">
      <div class="flex justify-between" @click="useGlobal.updatePayDialog(false)">
        <div class="flex text-xl font-bold mb-[20px] bg-currentflex items-center ">
          <NIcon size="25" color="#0e7a0d">
            <PaperPlaneOutline />
          </NIcon>
          <span class="ml-[8px]">商品支付</span>
        </div>
        <NIcon size="20" color="#0e7a0d" class="cursor-pointer">
          <CloseOutline />
        </NIcon>
      </div>
      <div class="p-4 ">
        <div><span class="whitespace-nowrap font-bold">需要支付：</span> <i class="text-xl text-[red] font-bold">{{ `￥${orderInfo.pkgInfo?.price}` }}</i></div>
        <div class="mt-2 flex">
          <span class="whitespace-nowrap font-bold">套餐名称：</span><span class="ml-2"> {{ orderInfo.pkgInfo?.name }}</span>
        </div>
        <div class="mt-2 flex">
          <span class="whitespace-nowrap font-bold">套餐描述：</span><span class="ml-2"> {{ orderInfo.pkgInfo?.des }} </span>
        </div>

        <div class="flex mt-10" :class="[isMobile ? 'flex-col' : 'flex-row', isRedirectPay ? 'flex-row-reverse' : '']">
          <div class=" flex flex-col" :class="[isMobile ? 'w-full ' : 'w-[400px] ']">
            <div id="paypal-button-container" @click="createPayment" >立即支付</div>
          </div>
        </div>
      </div>
    </div>
  </NModal>
</template>
