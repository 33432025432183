<script setup lang="ts">
import type { CallbackTypes } from "vue3-google-login";
import { decodeCredential, googleAuthCodeLogin, googleTokenLogin } from 'vue3-google-login'
import { useBasicLayout } from '@/hooks/useBasicLayout'
import { fetchGoogleLoginAPI, fetchGoogleCodeAPI, fetchWxLoginSaveInfoAPI } from '@/api/user'
import { fetchLoginAPI } from '@/api'
import type { FormInst, FormRules } from 'naive-ui'
import { useMessage, NModal, NForm, NFormItem, NInput } from 'naive-ui'
import { useAuthStore, useGlobalStore } from '@/store'
import { useRouter } from 'vue-router'
import { SvgIcon } from "@/components/common";
import { ref, computed } from "vue";
import { GoogleLogin } from 'vue3-google-login';
const authStore = useAuthStore()
const GlobalStore = useGlobalStore()
const router = useRouter()
const Nmessage = useMessage()
const formRef = ref<FormInst | null>(null)
const { isMobile } = useBasicLayout()
const userAgreement = computed(() => authStore.globalConfig.userAgreement)
const userPrivacy = computed(() => authStore.globalConfig.userPrivacy)
const registerForm = ref({
  consentTimestamp: '',
  userAgreementVersion: '',
  privacyPolicyVersion: ''
})

defineProps<Props>()
interface Props {
  visible: boolean
}

interface ResData {
  code: string
  prompt: string
  authuser: string
  scope: string
}

const callback: CallbackTypes.CredentialCallback = async (response: ResData) => {
  // const code = response.code;
  // await sendAuthCodeToBackend(code);
  const userData = decodeCredential(response.credential)
  let res = await fetchGoogleLoginAPI(userData)
  authStore.setToken(res?.data)
  authStore.getUserInfo()
  // GlobalStore.updateGoogleDialog(false)
  authStore.setLoginDialog(false)
  GlobalStore.updateLoginCount()
  loginForm.value.password = ''
  loginForm.value.email = ''
  verifyProtocol()
}


function verifyProtocol() {
  const consentState = computed(() => authStore.userInfo.consentState)
  if (!consentState.value) {
    initFormValue()
    verifyInfo()
  }
}

function initFormValue() {
  let Math1 = userAgreement.value.split('/').pop();
  let Math2 = userPrivacy.value.split('/').pop();
  let userAgreementMatch = Math1.split('.').shift();
  let userPrivacyMatch = Math2.split('.').shift();
  registerForm.value.privacyPolicyVersion = userPrivacyMatch
  registerForm.value.userAgreementVersion = userAgreementMatch
  registerForm.value.consentTimestamp = new Date().toISOString()
}

async function verifyInfo() {
  const params: any = registerForm.value
  await fetchWxLoginSaveInfoAPI(params)
}

async function loginGoogle() {
  let res = await googleTokenLogin({
    clientId: '936243708085-v0mr2uj5ua330l2e7f74cp8d04nqupsr.apps.googleusercontent.com'
  })
  const code = res.access_token
  sendAuthCodeToBackend(code)
}

const allowedDomains = [
  'gmail.com', 'yahoo.com', 'outlook.com', 'hotmail.com',
  'qq.com', '163.com', '126.com', 'protonmail.com',
  'aol.com', 'icloud.com', 'mail.com', 'gmx.com',
  'fastmail.com', 'web.de', 'mail.ru', 'yandex.ru',
  'orange.fr', 'laposte.net', 'naver.com', 'daum.net',
  'sina.com', 'sohu.com', 'zoho.com', 'tutanota.com',
  'hushmail.com', 'posteo.net'
];

function validateEmailWithDomain(email: string): boolean {
  const [_, domain] = email.split('@');
  return allowedDomains.includes(domain);
}


const loginForm = ref({
  password: '',
  email: '',
})

const rules: FormRules = {
  password: [
    { required: true, message: 'Password is required!', trigger: 'blur' },
    { min: 2, max: 20, message: '密码长度应为 2 到 30 个字符', trigger: 'blur' },
  ],
  email: [
    { 
      validator: (_: any, value: string) => {
        if (!value) {
          return Promise.reject('Email is required!');
        }
       
        if (!validateEmailWithDomain(value)) {
          return Promise.reject('Please enter a valid international email address.');
        }
        return Promise.resolve();
      },
      trigger: ['blur', 'change'],
    },
  ]
}

async function Login() {
  formRef.value?.validate(async (errors) => {
    if (!errors) {
      const params = { email: loginForm.value.email, password: loginForm.value.password }
      const res: any = await fetchLoginAPI(params)
      const { success, message } = res
      if (!success)
        return Nmessage.error(message)
      Nmessage.success('Account login successful, start the experience！')
      authStore.setToken(res.data)
      authStore.getUserInfo()
      authStore.setLoginDialog(false)
      GlobalStore.updateLoginCount()
      verifyProtocol()
    }
  })


}

function handleCloseDialog() {

}

async function handleOpenDialog() {

}

function Forgot() {
  authStore.setLoginDialog(false)
  GlobalStore.updateResetPassWordDialog(true)
}

function signIn(){
  GlobalStore.updateSignInGoogleDialog(true)
}

function closeDialog() {
  authStore.setLoginDialog(false)
}

// 将授权码发送到后端
const sendAuthCodeToBackend = async (code: string) => {
  try {

    let res = await fetchGoogleCodeAPI({ code })
    console.log(res)
    // router.push({ path: '/chat' });
  } catch (error) {
    console.error('Error sending code to backend:', error);
  }
};

</script>
<template>
  <NModal :show="visible" transform-origin="center"
   :style="{ width: isMobile ? '80%' : '900px', height: isMobile ? '80%' : 'auto' }"
    :on-after-enter="handleOpenDialog" :on-after-leave="handleCloseDialog">
    <main class="bg-white rounded-2xl">
      <div>
        <SvgIcon @click="closeDialog" class="close-icon" icon="line-md:close" />
      </div>
      <div class="Login-on" :class="isMobile ? 'w-[100%]' : 'w-[900px]'">
        <div class="Login-on-Left" v-show="!isMobile">
          <div class="Image">
            <img src="../../../assets/logins.png" alt="">
          </div>
        </div>
        <div class="Login-on-Right" :class="isMobile ? 'w-[100%]' : 'w-[60%]'">
          <div class="main-input-warp h-[456px]">
            <div class="Login-on-right-title text-[32px] text-[#191919] font-semibold">Log in</div>
            <div class="Login-on-right-desc mt-[7px]">
              <div class="Login-on-right-desc-item">
                <span class="circle"></span>
                <span class="desc">Use ChatGPT for free</span>
              </div>
              <div class="Login-on-right-desc-item">
                <span class="circle"></span>
                <span class="desc">Draw with AI for free</span>
              </div>
            </div>
            <div class="Login-on-right-btns mt-[35px] min-h-[50px]">
              <GoogleLogin :callback="callback" />
              <!-- <GoogleLogin>
                <div class="LoginBtn !w-[444px]">
                  <div class="img w-[24px]">
                    <img src="../../../assets/google.svg" alt="">
                  </div>
                  <span>Continue with Google</span>
                </div>
              </GoogleLogin> -->
              <!-- <div class="LoginBtn !w-[444px]" @click="loginGoogle" >
                  <div class="img w-[24px]">
                    <img src="../../../assets/google.svg" alt="">
                  </div>
                  <span>Continue with Google</span>
                </div> -->
            </div>
            <div class="Login-on-right-split">
              <div class="line"></div>
              <span>Or log in with</span>
              <div class="line"></div>
            </div>
            <div class="Login-on-right-input z-9999" :class="isMobile ? 'mt-2' : 'mt-10'">
              <NForm ref="formRef" :model="loginForm" :rules="rules">
                <NFormItem path="email">
                  <NInput v-model:value="loginForm.email" placeholder="Your Email">
                    <template #prefix>
                      <SvgIcon class="text-xl text-[#c7c7c7]" icon="mage:email" />
                    </template>
                  </NInput>
                </NFormItem>
                <NFormItem path="password">
                  <NInput type="password" v-model:value="loginForm.password" show-password-on="click" placeholder="Your Password">
                    <template #prefix>
                      <SvgIcon class="text-xl text-[#c7c7c7]" icon="lets-icons:lock" />
                    </template>
                  </NInput>
                </NFormItem>
              </NForm>
            </div>
            <div class="Login-on-right-policy">
              <span>
                By Log In, I agree to the
                <a :href="userAgreement" target="_blank" class="hover:underline" >Terms of Service</a>
                and
                <a :href="userPrivacy" target="_blank" class="hover:underline">Privacy Policy</a>
              </span>
            </div>
            <button class="emailBtn" @click="Login"> Log in </button>
            <div class="Login-on-right-footer">
              <div class="Login-on-right-footer-left font-medium" @click="signIn">
                No account, go to
                <a href="javascript:;" class="text-[#1c55f8] font-medium hover:underline">sign up</a>
              </div>
              <div class="Login-on-right-footer-right hover:underline" @click="Forgot">
                <a href="javascript:;" class="text-[#1c55f8] font-medium">Forgot password?</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </NModal>
</template>
<style scoped lang="less">
.close-icon {
  position: absolute;
  text-align: center;
  right: 32px;
  top: 40px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  z-index: 1;
  transition: background .2s ease;

  &:hover {
    background-color: #ededed;
  }
}

.Login-on {
  // width: 900px;
  // min-width: 800px;
  // height: 650px;
  overflow: hidden;
  display: flex;

  .Login-on-Left {
    display: block;
    width: 40%;

    .Image {
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
      position: relative;
      z-index: 1;
    }
  }

  .Login-on-Right {

    padding: 40px 48px;

    .Login-on-right-desc-item {
      display: flex;
      align-items: baseline;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.3;
      justify-content: flex-start;

      .circle {
        display: inline-block;
        flex-shrink: 0;
        width: 5px;
        height: 5px;
        background: #4c4c4c;
        border-radius: 50%;
        box-shadow: 0 4px 8px #00000014;
        margin-right: 7px;
        position: relative;
        top: -3px;
      }
    }
  }
}

.LoginBtn {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 12px 16px;
  background: var(--primary-background);
  border-radius: 8px;
  cursor: pointer;
  background-color: #f7f7f7;

  .img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
  }

  span {
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #191919;
    line-height: 22px;
    text-shadow: 0px 4px 8px 0px rgba(0, 0, 0, .08);
  }

  &:hover {
    background-color: #f0f0f0;
  }
}

.Login-on-right-split {
  display: flex;
  align-items: center;
  margin-top: 40px;

  .line {
    flex: 1;
    height: 0.5px;
    background: #d1d0d2;
  }

  span {
    margin: 0 15px;
    font-size: 16px;
    font-weight: 400;
    color: #7f7f7f;
    line-height: 18px;
  }
}

:deep(.n-input) {
  border-radius: 8px;
  height: 44px;
  transition: all .3s ease-in-out;
}

:deep(.n-form-item) {
  --n-feedback-height: 0px !important;
  --n-label-height: 15px !important;
}

:deep(.n-input .n-input__input-el) {
  height: 100%;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.emailBtn {
  transition: none;
  margin-top: 24px;
  width: 100%;
  height: 48px;
  font-size: 16px;
  background-color: #1c55f8;
  color: #fff;
  padding: 0 20px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  outline: none;
  border-radius: 24px;
}

.Login-on-right-policy {
  font-weight: 600;
  color: #7f7f7f;
  line-height: 19px;
  font-size: 12px;
  margin-top: 14px;

  a {
    margin-left: 4px;
    color: #1c55f8;
  }
}

.Login-on-right-footer {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  line-height: 1.4;

  .Login-on-right-footer-left {
    flex: 1;
    margin-right: 20px;
  }

  .Login-on-right-footer-right {
    flex: 1;
    text-align: right;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    color: #4c4c4c;
  }
}

div[aria-hidden="true"] {
  display: none !important
}

@media (max-width: 980px) {
  .Login-on .Login-on-Right {
    padding: 24px 20px;
  }
}

@media (max-width: 980px) {
  .Login-on-right-title {
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
  }
}

@media (max-width: 980px) {
  .close-icon {
    right: 25px;
    top: 25px;
    width: 28px;
    height: 28px;
  }
}

@media (max-width: 980px) {
  .Login-on-right-split {
    margin-top: 14px;
  }
}

@media (max-width: 980px) {
  .Login-on-right-split span {
    font-size: 12px;
    font-weight: 600;
  }
}

@media (max-width: 980px) {
  .emailBtn {
    margin-top: 8px;
    height: 39px;
    font-size: 14px;
  }
}

@media (max-width: 980px) {
  .Login-on .Login-on-Right .Login-on-right-desc-item {
    font-size: 14px;
  }
}

// div[aria-hidden="true"] {
// 	display: none;
// }

</style>