<script setup lang="ts">
import { fetchActivateAsEmail } from '@/api/user'
import { useMessage, NModal, NForm, NFormItem, NInput, NButton } from 'naive-ui'
import type { FormInst, FormRules } from 'naive-ui'
import { useAuthStore, useGlobalStore } from '@/store'
import { useBasicLayout } from '@/hooks/useBasicLayout'
import { useRouter } from 'vue-router'
import { SvgIcon } from "@/components/common";
import { ref } from "vue";
const formRef = ref<FormInst | null>(null)
const authStore = useAuthStore()
const GlobalStore = useGlobalStore()
const router = useRouter()
const Nmessage = useMessage()
const resetState = ref(false)
const loading = ref(false)
const { isMobile } = useBasicLayout()

defineProps<Props>()
interface Props {
  visible: boolean
}

interface ResData {
  code: string
  prompt: string
  authuser: string
  scope: string
}


const loginForm = ref({
  email: '',
  password: ''
})

const rules: FormRules = {
  email: [
    { required: true, message: 'Email is required!', trigger: 'blur' },
    { type: 'email', message: 'Please enter a valid email address.', trigger: ['blur', 'change'] },
  ],
  password: [
    { required: true, message: 'password is required!', trigger: 'blur' },
  ],
}

function handleCloseDialog() {

}

async function handleOpenDialog() {
}

async function handleClick() {
  formRef.value?.validate(async (errors) => {
    if (!errors) {
      try {
        if (!resetState.value) {
          // 提交表单信息
          loading.value = true
          let res = await fetchActivateAsEmail({ email: loginForm.value.email, password: loginForm.value.password })
          if (res) {
            loading.value = false
            resetState.value = true
          }
        }
      }
      catch (error) {
        loading.value = false
      }
    }
  })
}

function backClick() {
  GlobalStore.updateSignInGoogleDialog(false)
  authStore.setLoginDialog(true)
}

function BackToLogIn() {
  resetState.value = false
  GlobalStore.updateSignInGoogleDialog(false)
  authStore.setLoginDialog(true)
}

function closeDialog() {
  GlobalStore.updateSignInGoogleDialog(false)
}

</script>
<template>
  <NModal :show="visible" transform-origin="center"
    :style="{ width: isMobile ? '80%' : '600px', height: isMobile ? '80%' : '450px' }"
    :on-after-enter="handleOpenDialog" :on-after-leave="handleCloseDialog">
    <main class="bg-white rounded-2xl">
      <div>
        <SvgIcon @click="closeDialog" class="close-icon" icon="line-md:close" />
      </div>
      <div class="ResetPassWord-on">
        <div class="ResetPassword">
          <div class="ResetPassword-back" v-if="!resetState">
            <div class="ResetPassword-back-arrow" @click="backClick">
              <SvgIcon class="text-2xl" icon="ic:round-arrow-back" />
            </div>
            <span>Back</span>
          </div>
          <div class="ResetPassword-title">Register your account</div>
          <div class="ResetPassword-desc" v-if="!resetState">Please enter your email address to register your account
          </div>
          <div v-if="resetState">
            <div class="ResetPassword-desc1">We've sent you an email with instructions to activate your account</div>
            <div class="ResetPassword-desc2">Didn't receive the email? Check your spam folder or <span
                class="underline cursor-pointer">contact withour support team</span> .</div>
          </div>
          <div class="ResetPassword-content">
            <NForm ref="formRef" v-if="!resetState" :model="loginForm" :rules="rules">
              <NFormItem path="email">
                <NInput v-model:value="loginForm.email" placeholder="Your Email">
                  <template #prefix>
                    <SvgIcon class="text-xl text-[#c7c7c7]" icon="mage:email" />
                  </template>
                </NInput>
              </NFormItem>
              <NFormItem path="password">
                <NInput v-model:value="loginForm.password" type="password" show-password-on="click"
                  placeholder="Your password">
                  <template #prefix>
                    <SvgIcon class="text-xl text-[#c7c7c7]" icon="lets-icons:lock" />
                  </template>
                </NInput>
              </NFormItem>
            </NForm>
            <div>
              <NButton v-if="!resetState" class="Continue" :loading="loading" @click="handleClick">
                {{ !resetState ? 'Continue' : 'Back to Log In' }}
              </NButton>
              <NButton v-else class="Continue" @click="BackToLogIn">
                {{ 'Back to Log In' }}
              </NButton>
            </div>
          </div>
        </div>
      </div>
    </main>
  </NModal>
</template>
<style scoped lang="less">
.close-icon {
  position: absolute;
  text-align: center;
  right: 32px;
  top: 40px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  z-index: 1;
  transition: background .2s ease;

  &:hover {
    background-color: #ededed;
  }
}

.Login-on {
  width: 600px;
  min-width: 800px;
  height: 420px;
  overflow: hidden;
  display: flex;
}

.ResetPassword {
  padding: 50px 55px;

  .ResetPassword-back {
    display: flex;
    align-items: center;

    .ResetPassword-back-arrow {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      cursor: pointer;
      z-index: 1;
      transition: background .2s ease;
    }

    span {
      margin-left: 10px;
      font-size: 20px;
      font-weight: 400;
      text-align: left;
      color: #4c4c4c;
      line-height: 30px;
    }
  }

  .ResetPassword-title {
    margin-top: 30px;
    font-size: 32px;
    font-weight: 400;
    text-align: center;
    color: #191919;
    line-height: 48px;
  }

  .ResetPassword-desc {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 400;
    color: #191919;
    line-height: 22px;
    text-align: center;
  }

  .ResetPassword-desc1 {
    margin-top: 16px;
    font-size: 14px;
    font-weight: 600;
    color: #191919;
    line-height: 22px;
  }

  .ResetPassword-desc2 {
    margin-top: 14px;
    font-size: 14px;
    font-weight: 400;
    color: #191919;
    line-height: 22px;
  }

  .ResetPassword-content {
    padding: 0 22px;
    margin-top: 20px;

    .Continue {
      width: 100%;
      height: 48px;
      font-size: 16px;
      background-color: #1c55f8;
      padding: 0 20px;
      border-radius: 24px;
      border: none;
      transition: all .3s;
      cursor: pointer;
      font-weight: 500;
      outline: none;
      color: #fff;
      text-align: center;
      line-height: 48px;
      margin-top: 4px;
    }
  }
}

:deep(.n-input) {
  border-radius: 8px;
  height: 44px;
  transition: all .3s ease-in-out;
}

:deep(.n-input .n-input__input-el) {
  height: 100%;
  font-size: 16px;
}

:deep(.n-form-item) {
  --n-label-height:0 !important;
}

@media (max-width: 980px) {
  .ResetPassword {
    padding: 24px 20px 30px;
    height: auto;
    width: 78vw;
    min-width: 295px;
  }
}

@media (max-width: 980px) {
  .close-icon {
    right: 25px;
    top: 25px;
    width: 28px;
    height: 28px;
  }
}

@media (max-width: 980px) {
  .ResetPassword .ResetPassword-title {
    font-size: 24px
  }
}

@media (max-width: 980px) {
  .ResetPassword .ResetPassword-desc {
    font-size: 14px;
    text-align: center
  }
}

@media (max-width: 980px) {
  .Continue {
    margin-top: 8px;
    height: 39px !important;
    font-size: 14px !important;
  }
}
</style>