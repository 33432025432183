<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import { computed, onBeforeMount, ref } from 'vue'
import { useMessage } from 'naive-ui'
import { SvgIcon } from '@/components/common'
import { useAuthStore, useGlobalStoreWithOut } from '@/store'
import { fetchQueryMenuAPI } from '@/api/config'

interface MenuItem {
  id: number
  menuName: string
  menuPath: string
  menuIcon: string
  menuTipText: string
  menuIframeUrl: string
  isJump: boolean
  isNeedAuth: boolean
  activeImgIcon: string
  imgIcon: string
  useIconType: boolean
}
const menuLista = ref<MenuItem[]>([])
const message = useMessage()

async function queryMenu() {
  const res: any = await fetchQueryMenuAPI({ menuPlatform: 0 })
  if (!res.success)
    return
  menuLista.value = res.data
}
const useGlobalStore = useGlobalStoreWithOut()
const router = useRouter()
const route = useRoute()
const activeRoutePath = computed(() => route.path)
const authStore = useAuthStore()
const iframeSrc = computed(() => useGlobalStore.iframeUrl)
const isLogin = computed(() => authStore.isLogin)

function handleToPage(menu: MenuItem) {
  const { menuPath, isJump, menuIframeUrl, isNeedAuth } = menu

  if (isNeedAuth && !isLogin.value) {
    message.warning('请先登录后访问123！')
    authStore.setLoginDialog(true)
    // useGlobalStore.updateGoogleDialog(true)
    return
  }

  useGlobalStore.updateIframeUrl('')
  if (menuPath) {
    return router.push({ path: menuPath })
  }
  else {
    if (isJump) {
      window.open(menuIframeUrl)
    }
    else {
      useGlobalStore.updateIframeUrl(menuIframeUrl)
      router.push({ path: '/extend' })
    }
  }
}

function isActive(item: MenuItem) {
  const { menuIframeUrl, menuPath } = item
  if (menuIframeUrl)
    return menuIframeUrl === iframeSrc.value

  if (menuPath)
    return menuPath === activeRoutePath.value
}

onBeforeMount(() => {
  queryMenu()
})
</script>

<template>
  <footer class="dark:bg-[#19191b] fixed bottom-0 left-0 z-50 w-full bg-white h-[60px]">
    <div class="grid border-t py-1 dark:border-[#19191b] grid-cols-2 h-full  items-center" :style="{	gridTemplateColumns: `repeat(${menuLista.length}, minmax(0, 1fr))` }">
      <a v-for="(item) in menuLista" :key="item.id" :class="[isActive(item) ? 'text-[#1c55f8] dark:text-[#0057ff]' : '']" class="cursor-pointer text-center leading-4 " @click="handleToPage(item)">
        <span class="inline-block text-xl px-2 py-0.5 rounded-md " :class="[isActive(item) ? 'text-[#1c55f8] ' : '']">
          <SvgIcon :icon="item.menuIcon" class="mb-1 inline-block text-lg" v-if="item.useIconType === 1" />
          <img :src=" isActive(item) ? item.activeImgIcon : item.imgIcon" class="w-[22px] h-[22px] cursor-pointer  object-contain" v-if="item.useIconType === 2">
        </span>
        <p class="text-xs mb-1" :class="[isActive(item) ? ' dark:text-[#d5d4d4] text-[#1c55f8]' : '']" >{{ item.menuTipText }}</p>
      </a>
    </div>
  </footer>
</template>
