<script setup lang='ts'>
import { NButton, NModal, NScrollbar, NSkeleton } from 'naive-ui'
import { computed, ref, watchEffect } from 'vue'
import { marked } from 'marked'
import { useBasicLayout } from '@/hooks/useBasicLayout'
import { useAppStore, useGlobalStoreWithOut } from '@/store'
import { SvgIcon } from '@/components/common'

defineProps<Props>()
interface Props {
  visible: boolean
}

interface Notice {
  noticeInfo: string
  noticeTitle: string
}
const { isMobile } = useBasicLayout()
const appStore = useAppStore()
const useGlobalStore = useGlobalStoreWithOut()
const loading = ref(true)
const darkMode = computed(() => appStore.theme === 'dark')
const theme = computed(() => appStore.theme)
const packageDetail = computed(() => useGlobalStore.packageDetail)

const notice = ref<Notice>({
  noticeInfo: '',
  noticeTitle: '',
})

// const notice = computed<Notice>(() => ({
//   noticeInfo: packageDetail.value?.detail || '',
//   noticeTitle: packageDetail.value?.title || ''
// }))


const html = computed(() => {
  if (!notice.value.noticeInfo)
    return ''
  return marked(notice.value.noticeInfo)
})

function handleCloseDialog() {
  loading.value = true
  useGlobalStore.updatePackageDetail({})
}

function handleClose() {
  useGlobalStore.updatePackageDialog(false)
}

watchEffect(() => {
  if (packageDetail.value) {
    notice.value.noticeInfo = packageDetail.value.detail || ''
    notice.value.noticeTitle = packageDetail.value.title || ''
  }
})

function openDrawerAfter(){
  
}

</script>

<template>
  <NModal
    :show="visible" class="noticeDialog h-full w-11/12 md:w-3/5" :on-after-enter="openDrawerAfter" :on-after-leave="handleCloseDialog" :style="{
      backgroundColor: appStore.theme === 'dark' ? '#131314' : '#fff',
    }"
  >
    <div class="px-[15px] rounded-md relative">
      <div class="h-[100px] flex justify-between items-center" :class="isMobile ? '' : 'px-[30px]'">
        <div class="flex items-center">
          <span v-if="notice.noticeTitle" class="text-[24px] font-medium">{{ notice.noticeTitle }}</span>
          <NSkeleton v-if="loading && !notice?.noticeTitle" class="ml-5" height="40px" width="150px" />
        </div>
        <span v-if="!isMobile">
       
        </span>
        <span class="absolute right-[12px] top-[12px] rounded-sm hover:bg-[#e8e8e8] transition-all cursor-pointer p-[2px]">
          <SvgIcon icon="ic:twotone-close" class="text-[20px]" @click="handleClose" />
        </span>
      </div>
      <div v-if="loading && !html" class="px-[20px]">
        <NSkeleton text :repeat="15" />
      </div>
      <div :class="[isMobile ? 'p-[0px]  max-h-[calc(100vh-100px)]' : 'p-[0px]', darkMode ? 'text-[#9e9d9d]' : 'text-[#000]']">
        <NScrollbar>
          <div style="line-height: 2;" :class="[isMobile ? 'p-[0px]  max-h-[calc(100vh-200px)]' : 'p-[30px]  max-h-[55vh]', darkMode ? 'text-[#9e9d9d]' : 'text-[#000]']" class="!text-[14px] markdown-body markdown-body-generate pb-4" v-html="html" />
        </NScrollbar>
      </div>
      <div class="flex w-full pb-6 pt-5 px-5 items-end  justify-end ">
        <NButton type="primary" ghost size="small" class="!ml-[15px] my" @click="handleClose" :style="{backgroundColor: appStore.theme === 'dark' ? '#19191b' : '#dee8ff', color: appStore.theme === 'dark' ? '#9e9d9d' : ''  }"  >
          Close Window
        </NButton>
      </div>
    </div>
  </NModal>
</template>

<style scoped>

</style>
