import { get, post } from '@/utils/request'

/* order buy */
export function fetchOrderBuyAPI<T>(data: { goodsId: number }): Promise<T> {
  return post<T>({
    url: '/order/buy',
    data,
  })
}

/* order query */
export function fetchOrderQueryAPI<T>(data: { orderId: string }): Promise<T> {
  return get<T>({
    url: '/order/queryByOrderId',
    data,
  })
}

/* paypal CreateOrder */
export function fetchPayPalCreateOrderAPI<T>(data: { goodsId: number, currency: string }): Promise<T> {
  return post<T>({
    url: '/pay/create-order',
    data,
  })
}

/* paypal onApproveOrder */
export function fetchPayPalApproveOrderAPI<T>(data: { paymentId?: string, PayerID: string }): Promise<T> {
  return get<T>({
    url: '/pay/execute-order',
    data
  })
}